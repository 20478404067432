<div class="container-fluid px-4 max-w-100">
    <div class="row">
        <div class="mobile-tab pt-3">
            <mat-tab-group>
                <mat-tab label="Certification Wizard"> 
                    <ng-template [ngTemplateOutlet]="CertificationWizard"></ng-template>
                </mat-tab>
                <mat-tab label="Certification Requirement">
                    <div class="py-3">
                        <app-cert-wiz-controller></app-cert-wiz-controller>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 py-4 hide-mobile">
            <app-cert-wiz-controller></app-cert-wiz-controller>
        </div>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 py-4 hide-mobile">
            <ng-template [ngTemplateOutlet]="CertificationWizard"></ng-template>
        </div>
    </div>
</div>

<!--after conversion-->
<ng-template #CertificationWizard>
    <div class="py-3 primary">
        <h2>We need to ask you a few questions to get you started on the right application.</h2>
        <h3>By answering these questions we can ensure we gather the right information from you to complete the application
            so that the process is as seamless and quick as possible</h3>
        <div class="horz-div"></div>
        <mat-progress-bar mode="determinate" color="accent" [value]="progressValue()" style="margin-bottom:20px;margin-top:20px;"></mat-progress-bar>
        <div style="margin-left: auto; margin-right:auto;" *ngIf="!certWizService.certWizard.isComplete">
            <app-question-wizard  [questions]="certWizService.certWizard.questions"></app-question-wizard>
        </div>
        <div style="margin-left: auto; margin-right:auto;" *ngIf="certWizService.certWizard.isComplete">
            <h3>The system is ready to start an application.</h3>
            <div class="d-flex justify-content-center flex-wrap">
                <button mat-stroked-button color="accent" (click)="back()" class="my-2">Back</button>
                <button (click)="createNewApp()" mat-stroked-button color="accent" aria-label="New Application" class="mx-2 my-2">Launch Application</button>
            </div>
        </div>
    </div>
</ng-template>