<div class="container px-0 max-w-100">
    <div class="row mx-0">
        <div class="mobile-tab pt-3" (click)="setIsMobile(true)">
            <mat-tab-group [selectedIndex]="_ui.selectedTab.appWizard" (selectedIndexChange)="_ui.selectedTab.appWizard =$event">
                <mat-tab  label="Certification Requirement">
                    <div class="p-3">
                        <app-background-review [bgrData]="bgrData"></app-background-review>
                        <div class="card my-1">
                            <div class="d-flex">
                                <mat-icon color="accent" class="title-icon">dashboard_customize</mat-icon>
                                <h2>Actions</h2>
                            </div>
                            <a class="my-1 text-decoration-none action-btn bg-white" color="accent" mat-stroked-button [routerLink]="['/app/cert-wizard']">Start new application</a>
                            <a class="my-1 text-decoration-none action-btn bg-white" color="accent" mat-stroked-button (click)="dialogService.requestApplicantResubmit(addWizObj.appId)" *ngIf="userService.isEduAgent() && checkAgentSignatureIsUnlocked()">Request Applicant Resubmit</a>
                        </div>
                        <app-cert-wiz-controller [isApp]="true" [addWizObj]="addWizObj"></app-cert-wiz-controller>
                    </div>
                </mat-tab>
                <mat-tab label="Application Wizard"> 
                    <div class="p-3 primary">
                        <h2>Application Wizard - Please complete each section</h2>
                        <div class="d-flex">
                            <h4 class="me-2">Applicant Name: <b (click)="dialogService.viewCertificates(addWizObj.applicantId)" class="btn link p-0 text-decoration-underline">{{personalInfo?.firstName}} {{personalInfo?.lastName}}</b></h4>
                            <h4> | </h4>
                            <h4 class="mx-2">Applicant ALSDE ID: <b (click)="dialogService.viewCertificates(addWizObj.applicantId)" class="btn link p-0 text-decoration-underline">{{addWizObj.applicantId}}</b></h4>
                            <h4 *ngIf="getAppPDFDocId()"> | </h4>
                            <h4 *ngIf="getAppPDFDocId()" class="mx-2"><button class="btn btn-link pt-0" (click)="viewDocument()">Application</button></h4>
                        </div>
                        <div class="test-header">Status: <div class="test-val" style="color:goldenrod;">{{addWizObj.status?.Value}}</div></div>
                        <div class="test-header">Approach: <div class="test-val">{{addWizObj.approach}}</div></div>
                        <div class="test-header">Application ID: <div class="test-val">{{addWizObj.appId}}</div></div>
                        <div class="test-header">Start Date: <div class="test-val">{{addWizObj.startedDate | date:'MM/dd/yyyy'}}</div></div>
                        <div class="test-header">Fee: <div class="test-val" style="color:green;">${{addWizObj.feeAmount}}</div></div>
                        <div class="test-header">Submit Date: <div class="test-val">{{addWizObj.submittedDate | date:'MM/dd/yyyy'}}</div></div>
                        <div class="test-header">Application Complete Date: <div class="test-val">{{addWizObj.packageCompleteDate | date:'MM/dd/yyyy'}}</div></div>
                        <div class="test-header">Application Decision Date: <div class="test-val">{{addWizObj.decisionDate | date:'MM/dd/yyyy'}}</div></div>
                        <div class="test-header" style="display: grid;"><button mat-stroked-button color="accent" (click)="deleteApp()" *ngIf="addWizObj.status?.Value=='started'">Delete</button></div>
                        <ng-template *ngTemplateOutlet="AppWizard; context:{isMobile:true}"></ng-template>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>

        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 py-4 hide-mobile left-bar-desktop" (click)="setIsMobile(false)">
            <app-background-review [bgrData]="bgrData"></app-background-review>
            <div class="card my-1">
                <div class="d-flex">
                    <mat-icon color="accent" class="title-icon">dashboard_customize</mat-icon>
                    <h2>Actions</h2>
                </div>
                <a class="my-1 text-decoration-none action-btn bg-white" color="accent" mat-stroked-button [routerLink]="['/app/cert-wizard']">Start new application</a>
                <a class="my-1 text-decoration-none action-btn bg-white" color="accent" mat-stroked-button (click)="dialogService.requestApplicantResubmit(addWizObj.appId)" *ngIf="userService.isEduAgent() && checkAgentSignatureIsUnlocked()">Request Applicant Resubmit</a>
            </div>
            <app-cert-wiz-controller [isApp]="true" [addWizObj]="addWizObj"></app-cert-wiz-controller>
        </div>
        <div class="container col-sm-12 col-md-9 col-lg-9 col-xl-9 py-4 hide-mobile" (click)="setIsMobile(false)">
            <div class="primary">
                <h2>Application Wizard - Please complete each section</h2>
                <div class="d-flex">
                    <h4 class="me-2">Applicant Name: <b (click)="dialogService.viewCertificates(addWizObj.applicantId)" class="btn link p-0 text-decoration-underline">{{personalInfo?.firstName}} {{personalInfo?.lastName}}</b></h4>
                    <h4> | </h4>
                    <h4 class="mx-2">Applicant ALSDE ID: <b (click)="dialogService.viewCertificates(addWizObj.applicantId)" class="btn link p-0 text-decoration-underline">{{addWizObj.applicantId}}</b></h4>
                    <h4 *ngIf="getAppPDFDocId()"> | </h4>
                    <h4 *ngIf="getAppPDFDocId()" class="mx-2"><button class="btn btn-link pt-0" (click)="viewDocument()">Application</button></h4>
                </div>
                <div class="test-header">Status: <div class="test-val" style="color:goldenrod;">{{addWizObj.status?.Value}}</div></div>
                <div class="test-header">Approach: <div class="test-val">{{addWizObj.approach}}</div></div>
                <div class="test-header">Application ID: <div class="test-val">{{addWizObj.appId}}</div></div>
                <div class="test-header">Start Date: <div class="test-val">{{addWizObj.startedDate | date:'MM/dd/yyyy'}}</div></div>
                <div class="test-header">Fee: <div class="test-val" style="color:green;">{{addWizObj.feeAmount | currency}}</div></div>
                <div class="test-header">Submit Date: <div class="test-val">{{addWizObj.submittedDate | date:'MM/dd/yyyy'}}</div></div>
                <div class="test-header">Application Complete Date: <div class="test-val">{{addWizObj.packageCompleteDate | date:'MM/dd/yyyy'}}</div></div>
                <div class="test-header">Application Decision Date: <div class="test-val">{{addWizObj.decisionDate | date:'MM/dd/yyyy'}}</div></div>
                <div class="test-header" style="display: grid;"><button mat-stroked-button color="accent" (click)="deleteApp()" *ngIf="addWizObj.status?.Value=='started'">Delete</button></div>
                <ng-template *ngTemplateOutlet="AppWizard; context:{isMobile:false}"></ng-template>
            </div>
        </div>
    </div>
</div>

<ng-template #AppWizard let-isMobile="isMobile">
    <mat-stepper orientation="vertical" [linear]="false" #stepper (selectionChange)="onOpenClose($event);selectionChanged()" *ngIf="this.appWizService.appWizard.artifacts.length" [ngClass]="{'close-all':closeAll}" (click)="onSteperClick()">
        <ng-container *ngFor="let artifact of addWizObj.artifacts; let index=index">
            <mat-step *ngIf="artifact.key == ARTIFACT.PERSONAL" [stepControl]="$any(stepperFormGroup.get(ARTIFACT.PERSONAL))" [label]="artifact.artifactId" color="primary" [completed]="[3,4].includes(artifact.artifactStatus)" [state]="countWords[index]" errorMessage="All fields are required.">
                <ng-template matStepLabel>Personal Data:- Review your personal data. If correct, scroll down and click next to complete this section.</ng-template>
                <app-personal-data 
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile" 
                    [submit$]="submit$" 
                    [reset$]="reset$"
                    [personalInfo]="personalInfo"
                ></app-personal-data>
                <app-artifact-action
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile"
                    [submit$]="submit$"
                    [reset$]="reset$"
                ></app-artifact-action>
            </mat-step>
            <mat-step  *ngIf="artifact.key == ARTIFACT.CITIZENSHIP" [stepControl]="$any(stepperFormGroup.get(ARTIFACT.CITIZENSHIP))" [label]="artifact.artifactId" color="primary" [completed]="[3,4].includes(artifact.artifactStatus)" [state]="countWords[index]" errorMessage="Proof of citizenship is required.">
                <ng-template matStepLabel>Proof of Citizenship</ng-template>
                <app-proof-of-citizenship 
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile" 
                    [submit$]="submit$" 
                    [reset$]="reset$"
                ></app-proof-of-citizenship>
                <app-artifact-action
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile"
                    [submit$]="submit$"
                    [reset$]="reset$"
                ></app-artifact-action>
            </mat-step>
            <mat-step  *ngIf="artifact.key == ARTIFACT.MILITARY" [stepControl]="$any(stepperFormGroup.get(ARTIFACT.MILITARY))" [label]="artifact.artifactId" color="primary" [completed]="[3,4].includes(artifact.artifactStatus)" [state]="countWords[index]" errorMessage="Military declaration is required.">
                <ng-template matStepLabel>Military Declaration</ng-template>
                <app-military-declaration 
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile" 
                    [submit$]="submit$" 
                    [reset$]="reset$"
                ></app-military-declaration>
                <app-artifact-action
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile"
                    [submit$]="submit$"
                    [reset$]="reset$"
                ></app-artifact-action>
            </mat-step>
            <mat-step  *ngIf="artifact.key == ARTIFACT.CRIMINAL" [stepControl]="$any(stepperFormGroup.get(ARTIFACT.CRIMINAL))" [label]="artifact.artifactId" color="primary" [completed]="[3,4].includes(artifact.artifactStatus)" [state]="countWords[index]" errorMessage="All fields are required.">
                <ng-template matStepLabel>Professional Status & Criminal History</ng-template>
                <app-professional-status-criminal-history 
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile" 
                    [submit$]="submit$" 
                    [reset$]="reset$" 
                ></app-professional-status-criminal-history>
                <app-artifact-action
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile"
                    [submit$]="submit$"
                    [reset$]="reset$"
                ></app-artifact-action>
            </mat-step>
            <mat-step  *ngIf="artifact.key == ARTIFACT.EDUCATIONAL" [stepControl]="$any(stepperFormGroup.get(ARTIFACT.EDUCATIONAL))" [label]="artifact.artifactId" color="primary" [completed]="[3,4].includes(artifact.artifactStatus)" [state]="countWords[index]" errorMessage="One record for both educational and experience are required.">
                <ng-template matStepLabel>Education Record</ng-template>
                <app-education-record 
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile" 
                    [submit$]="submit$" 
                    [reset$]="reset$" 
                ></app-education-record>
                <app-artifact-action
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile"
                    [submit$]="submit$"
                    [reset$]="reset$"
                ></app-artifact-action>
            </mat-step>
            <mat-step  *ngIf="artifact.key == ARTIFACT.EDUCATIONAL_SUBSTITUTE" [stepControl]="$any(stepperFormGroup.get(ARTIFACT.EDUCATIONAL_SUBSTITUTE))" [label]="artifact.artifactId" color="primary" [completed]="[3,4].includes(artifact.artifactStatus)" [state]="countWords[index]" errorMessage="One record for both educational and experience are required.">
                <ng-template matStepLabel>Education Record Substitute</ng-template>
                <app-education-record-substitute
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile" 
                    [submit$]="submit$" 
                    [reset$]="reset$" 
                ></app-education-record-substitute>
                <app-artifact-action
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile"
                    [submit$]="submit$"
                    [reset$]="reset$"
                ></app-artifact-action>
            </mat-step>
            <mat-step  *ngIf="artifact.key == ARTIFACT.EDUCATIONAL_TE" [stepControl]="$any(stepperFormGroup.get(ARTIFACT.EDUCATIONAL_TE))" [label]="artifact.artifactId" color="primary" [completed]="[3,4].includes(artifact.artifactStatus)" [state]="countWords[index]" errorMessage="One record for educational is required.">
                <ng-template matStepLabel>Education Record Career and Technical Education</ng-template>
                <app-education-record-te
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile" 
                    [submit$]="submit$" 
                    [reset$]="reset$" 
                ></app-education-record-te>
                <app-artifact-action
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile"
                    [submit$]="submit$"
                    [reset$]="reset$"
                ></app-artifact-action>
            </mat-step>
            <mat-step  *ngIf="artifact.key == ARTIFACT.EDUCATIONAL_HS" [stepControl]="$any(stepperFormGroup.get(ARTIFACT.EDUCATIONAL_HS))" [label]="artifact.artifactId" color="primary" [completed]="[3,4].includes(artifact.artifactStatus)" [state]="countWords[index]" errorMessage="One record for educational is required.">
                <ng-template matStepLabel>Education Record Health Science </ng-template>
                <app-education-record-hs
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile" 
                    [submit$]="submit$" 
                    [reset$]="reset$" 
                ></app-education-record-hs>
                <app-artifact-action
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile"
                    [submit$]="submit$"
                    [reset$]="reset$"
                ></app-artifact-action>
            </mat-step>
            <mat-step  *ngIf="artifact.key == ARTIFACT.EXPERIENCE" [stepControl]="$any(stepperFormGroup.get(ARTIFACT.EXPERIENCE))" [label]="artifact.artifactId" color="primary" [completed]="[3,4].includes(artifact.artifactStatus)" [state]="countWords[index]" errorMessage="One record for both educational and experience are required.">
                <ng-template matStepLabel>Educational Experience</ng-template>
                <app-educational-experience 
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile" 
                    [submit$]="submit$" 
                    [reset$]="reset$" 
                ></app-educational-experience>
                <app-artifact-action
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile"
                    [submit$]="submit$"
                    [reset$]="reset$"
                ></app-artifact-action>
            </mat-step>
            <mat-step  *ngIf="artifact.key == ARTIFACT.PRAXIS" [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))" [label]="artifact.artifactId" color="primary" [completed]="[3,4].includes(artifact.artifactStatus)" [state]="countWords[index]" errorMessage="One record for praxisAssessment is required.">
                <ng-template matStepLabel>Praxis Assessment List</ng-template>
                <app-praxxis-assessment-list 
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile" 
                    [submit$]="submit$" 
                    [reset$]="reset$" 
                ></app-praxxis-assessment-list>
                <app-artifact-action
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile"
                    [submit$]="submit$"
                    [reset$]="reset$"
                ></app-artifact-action>
            </mat-step>
            <mat-step  *ngIf="artifact.key == ARTIFACT.INTERNSHIP_PLACEMENT" [stepControl]="$any(stepperFormGroup.get(ARTIFACT.INTERNSHIP_PLACEMENT))" [label]="artifact.artifactId" color="primary" [completed]="[3,4].includes(artifact.artifactStatus)" [state]="countWords[index]" errorMessage="One record for Internship Placement is required.">
                <ng-template matStepLabel>Internship Placement</ng-template>
                <app-internship-placement
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile" 
                    [submit$]="submit$" 
                    [reset$]="reset$" 
                ></app-internship-placement>
                <app-artifact-action
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile"
                    [submit$]="submit$"
                    [reset$]="reset$"
                ></app-artifact-action>
            </mat-step>
            <mat-step  *ngIf="artifact.key == ARTIFACT.CLASS_AA" [stepControl]="$any(stepperFormGroup.get(ARTIFACT.CLASS_AA))" [label]="artifact.artifactId" color="primary" [completed]="[3,4].includes(artifact.artifactStatus)" [state]="countWords[index]" errorMessage="All fields are required.">
                <ng-template matStepLabel>Class AA General Information</ng-template>
                <app-class-info-aa
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile" 
                    [submit$]="submit$" 
                    [reset$]="reset$" 
                ></app-class-info-aa>
                <app-artifact-action
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile"
                    [submit$]="submit$"
                    [reset$]="reset$"
                ></app-artifact-action>
            </mat-step>
            <mat-step  *ngIf="artifact.key == ARTIFACT.CLASS_A_ALT" [stepControl]="$any(stepperFormGroup.get(ARTIFACT.CLASS_A_ALT))" [label]="artifact.artifactId" color="primary" [completed]="[3,4].includes(artifact.artifactStatus)" [state]="countWords[index]" errorMessage="All fields are required.">
                <ng-template matStepLabel>Alternative Class A General Information</ng-template>
                <app-class-info-a-alternative
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile" 
                    [submit$]="submit$" 
                    [reset$]="reset$" 
                ></app-class-info-a-alternative>
                <app-artifact-action
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile"
                    [submit$]="submit$"
                    [reset$]="reset$"
                ></app-artifact-action>
            </mat-step>
            <mat-step  *ngIf="artifact.key == ARTIFACT.CLASS_A_TRD" [stepControl]="$any(stepperFormGroup.get(ARTIFACT.CLASS_A_TRD))" [label]="artifact.artifactId" color="primary" [completed]="[3,4].includes(artifact.artifactStatus)" [state]="countWords[index]" errorMessage="All fields are required.">
                <ng-template matStepLabel>Traditional Class A General Information</ng-template>
                <app-class-info-a-traditional
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile" 
                    [submit$]="submit$" 
                    [reset$]="reset$" 
                ></app-class-info-a-traditional>
                <app-artifact-action
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile"
                    [submit$]="submit$"
                    [reset$]="reset$"
                ></app-artifact-action>
            </mat-step>
            <mat-step  *ngIf="artifact.key == ARTIFACT.CLASS_B" [stepControl]="$any(stepperFormGroup.get(ARTIFACT.CLASS_B))" [label]="artifact.artifactId" color="primary" [completed]="[3,4].includes(artifact.artifactStatus)" [state]="countWords[index]" errorMessage="All fields are required.">
                <ng-template matStepLabel>Class B General Information</ng-template>
                <app-class-info-b
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile" 
                    [submit$]="submit$" 
                    [reset$]="reset$" 
                ></app-class-info-b>
                <app-artifact-action
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile"
                    [submit$]="submit$"
                    [reset$]="reset$"
                ></app-artifact-action>
            </mat-step>
            <mat-step  *ngIf="artifact.key == ARTIFACT.SCHOOL_PLACEMENT" [stepControl]="$any(stepperFormGroup.get(ARTIFACT.SCHOOL_PLACEMENT))" [label]="artifact.artifactId" color="primary" [completed]="[3,4].includes(artifact.artifactStatus)" [state]="countWords[index]" errorMessage="One record for School Placement is required.">
                <ng-template matStepLabel>School Placement</ng-template>
                <app-school-placement
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile" 
                    [submit$]="submit$" 
                    [reset$]="reset$" 
                ></app-school-placement>
                <app-artifact-action
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile"
                    [submit$]="submit$"
                    [reset$]="reset$"
                ></app-artifact-action>
            </mat-step>
            <mat-step  *ngIf="artifact.key == ARTIFACT.TEAMS_CONTRACT_DETAILS" [stepControl]="$any(stepperFormGroup.get(ARTIFACT.TEAMS_CONTRACT_DETAILS))" [label]="artifact.artifactId" color="primary" [completed]="[3,4].includes(artifact.artifactStatus)" [state]="countWords[index]" errorMessage="All fields are required.">
                <ng-template matStepLabel>Contract Details</ng-template>
                <app-teams-contract-details
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile" 
                    [submit$]="submit$" 
                    [reset$]="reset$" 
                ></app-teams-contract-details>
                <app-artifact-action
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile"
                    [submit$]="submit$"
                    [reset$]="reset$"
                ></app-artifact-action>
            </mat-step>
            <mat-step  *ngIf="artifact.key == ARTIFACT.ACCEPTANCE" [stepControl]="$any(stepperFormGroup.get(ARTIFACT.ACCEPTANCE))" [label]="artifact.artifactId" color="primary" [completed]="[3,4].includes(artifact.artifactStatus)" [state]="countWords[index]" errorMessage="Acceptance & Signature is required.">
                <ng-template matStepLabel>Acceptance & Signature</ng-template>
                <app-acceptance-signature 
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile" 
                    [submit$]="submit$" 
                    [reset$]="reset$" 
                ></app-acceptance-signature>
                <app-artifact-action
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile"
                    [submit$]="submit$"
                    [reset$]="reset$"
                ></app-artifact-action>
            </mat-step>
            <mat-step  *ngIf="artifact.key == ARTIFACT.ACCEPTANCE_IHE" [stepControl]="$any(stepperFormGroup.get(ARTIFACT.ACCEPTANCE_IHE))" [label]="artifact.artifactId" color="primary" [completed]="[3,4].includes(artifact.artifactStatus)" [state]="countWords[index]" errorMessage="Acceptance & Signature is required.">
                <ng-template matStepLabel>Acceptance & Signature of Institute of Higher Education</ng-template>
                <app-acceptance-signature 
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile" 
                    [submit$]="submit$" 
                    [reset$]="reset$" 
                ></app-acceptance-signature>
                <app-artifact-action
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile"
                    [submit$]="submit$"
                    [reset$]="reset$"
                ></app-artifact-action>
            </mat-step>
            <mat-step *ngIf="artifact.key == ARTIFACT.ACCEPTANCE_LEA" 
          [stepControl]="$any(stepperFormGroup.get(ARTIFACT.ACCEPTANCE_LEA))" 
          [label]="artifact.artifactId" 
          color="primary" 
          [completed]="[3,4].includes(artifact.artifactStatus)" 
          [state]="countWords[index]" 
          errorMessage="Acceptance & Signature is required.">
    <ng-template matStepLabel>Acceptance & Signature of Education Agency</ng-template>
    <app-acceptance-signature 
        [artifact]="artifact"
        [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
        [isMobile]="isMobile" 
        [submit$]="submit$" 
        [reset$]="reset$" 
    ></app-acceptance-signature>
    <app-artifact-action
        [artifact]="artifact"
        [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
        [isMobile]="isMobile"
        [submit$]="submit$"
        [reset$]="reset$"
    ></app-artifact-action>
</mat-step>

<mat-step *ngIf="artifact.key == ARTIFACT.ACCEPTANCE_3rdParty" 
          [stepControl]="$any(stepperFormGroup.get(ARTIFACT.ACCEPTANCE_3rdParty))" 
          [label]="artifact.artifactId" 
          color="primary" 
          [completed]="[3,4].includes(artifact.artifactStatus)" 
          [state]="countWords[index]" 
          errorMessage="Acceptance & Signature is required.">
    <ng-template matStepLabel>Acceptance & Signature of 3rd Party</ng-template>
    <app-acceptance-signature 
        [artifact]="artifact"
        [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
        [isMobile]="isMobile" 
        [submit$]="submit$" 
        [reset$]="reset$" 
    ></app-acceptance-signature>
    <app-artifact-action
        [artifact]="artifact"
        [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
        [isMobile]="isMobile"
        [submit$]="submit$"
        [reset$]="reset$"
    ></app-artifact-action>
</mat-step>
            <mat-step  *ngIf="artifact.key == ARTIFACT.ACCEPTANCE_LEA_TEAMS" [stepControl]="$any(stepperFormGroup.get(ARTIFACT.ACCEPTANCE_LEA_TEAMS))" [label]="artifact.artifactId" color="primary" [completed]="[3,4].includes(artifact.artifactStatus)" [state]="countWords[index]" errorMessage="Acceptance & Signature is required.">
                <ng-template matStepLabel>Acceptance & Signature of Teams</ng-template>
                <app-acceptance-signature 
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile" 
                    [submit$]="submit$" 
                    [reset$]="reset$" 
                ></app-acceptance-signature>
                <app-artifact-action
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile"
                    [submit$]="submit$"
                    [reset$]="reset$"
                ></app-artifact-action>
            </mat-step>
            <mat-step  *ngIf="artifact.key == ARTIFACT.MOREINFO" [stepControl]="stepperFormGroup.controls[ARTIFACT.MOREINFO]" [label]="artifact.artifactId" color="primary" [completed]="[3,4].includes(artifact.artifactStatus)" [state]="countWords[index]" errorMessage="All type documents upload is required.">
                <ng-template matStepLabel>More Information Needed</ng-template>
                <app-more-information-needed 
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile" 
                    [submit$]="submit$" 
                    [reset$]="reset$" 
                ></app-more-information-needed>
                <app-artifact-action
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile"
                    [submit$]="submit$"
                    [reset$]="reset$"
                ></app-artifact-action>
            </mat-step>
            <mat-step  *ngIf="artifact.key == ARTIFACT.MENTOR" [stepControl]="$any(stepperFormGroup.get(ARTIFACT.MENTOR))" [label]="artifact.artifactId" color="primary" [completed]="[3,4].includes(artifact.artifactStatus)" [state]="countWords[index]" errorMessage="ALSDE ID is required.">
                <ng-template matStepLabel>Teaching Mentor</ng-template>
                <app-alsde-person
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile" 
                    [submit$]="submit$" 
                    [reset$]="reset$" 
                ></app-alsde-person>
                <app-artifact-action
                    [artifact]="artifact"
                    [stepControl]="$any(stepperFormGroup.get(artifact.artifactId))"
                    [isMobile]="isMobile"
                    [submit$]="submit$"
                    [reset$]="reset$"
                ></app-artifact-action>
            </mat-step>
        </ng-container>
        
        <ng-container *ngFor="let countWord of countWords; let index=index">
            <ng-template matStepperIcon="{{countWord}}"><span>{{index+1}}</span></ng-template>
        </ng-container>
    </mat-stepper>
</ng-template>